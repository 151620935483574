var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("img", {
      attrs: {
        src: require("@/assets/aboutUs/us.png"),
        width: "100%",
        height: "600px",
      },
    }),
    _c("div", { staticClass: "title title_level1" }, [
      _vm._v("智迩科技——数字化转型时代的创新IT服务商"),
    ]),
    _c(
      "div",
      { staticClass: "contents" },
      [
        _c(
          "a-row",
          { attrs: { gutter: 48 } },
          [
            _c("a-col", { attrs: { span: 10 } }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/aboutUs/enterprise.png"),
                  width: "100%",
                  height: "945px",
                },
              }),
            ]),
            _c("a-col", { attrs: { span: 14 } }, [
              _c("p", { staticClass: "normal content_nr" }, [
                _vm._v(
                  " 智迩科技（Cheer Tech），是一家专注于为客户提供云计算IaaS、PaaS层基础设施的解决方案 和全生命周期的智能运维服务，为客户持续创造业务价值的企业。 "
                ),
              ]),
              _c("p", { staticClass: "normal content_nr" }, [
                _vm._v(
                  " Cheer Tech凭借多年丰富的金融、政府、运营商的IT运营经验， 借助云计算、大数据、SDN、虚拟化、容器、备份等技术， 通过网络基础架构、信息安全与服务、计算资源存储资源集成， 结合自身卓越的设计、实施、服务能力，满足国家对安全可控、信创的要求， 为客户提供标准化/定制化的云计算全IT解决方案与全生命周期的综合IT服务。 服务内容涵盖咨询服务、规划设计、系统集成、运维服务等全生命周期。 "
                ),
              ]),
              _c("p", { staticClass: "normal content_nr" }, [
                _vm._v(
                  " 自研产品服务 Cheer Tech现拥有CheerOcean Cloud，即云管理平台（Cloud Management Platform，CMP）系列产品。 1. CheerOcean MaaS（Middleware as a service）开源/商业混合中间件治理系统。 凭借对中间件、数据库等PaaS层核心模块的深度行业洞察和理解， 对主流的web服务中间件、消息服务中间件、缓存服务中间件进行深度的监、管、控， 满足客户在日益庞大复杂的IT系统下数十种不同种类、上百种版本的复杂中间件环境的运维、开发管理， 通过赋予用户丰富的存量探测纳管、统一基线标准、漏洞补丁自动修复、动态限流、 灰度发布等中间件能力解决用户因为中间件出现的业务中断问题， 降低用户IT业务风险，提高用户IT运营效率。 "
                ),
              ]),
              _c("p", { staticClass: "normal content_nr" }, [
                _vm._v(
                  " 2. CheerVM 数据保护与备份平台， 通过对物理机、虚拟机、大型存储、容器、Office等SaaS服务的数据 进行备份、验证、恢复实现客户全IT从传统架构转向私有云/公有云/云原生的 混合云模式下数据的保护与备份能力，赋能用户的数据安全。 "
                ),
              ]),
              _c("p", { staticClass: "normal content_nr" }, [
                _vm._v(
                  " CheerTech已经与华为、腾讯云、听云、东方通、F5、veeam等国内外一流科技公司形成产品互补、 生态共赢的良好合作，并已取得相应专利、商标及国家软件著作权。 截至目前，公司已取得了ISO20000信息技术服务管理体系认证， ISO27001信息安全管理体系认证，ISO9001质量管理体系认证，ISO14001环境管理体系认证， ITSS信息技术服务标准三级认证。公司同时还是华为的金牌合作伙伴。 "
                ),
              ]),
              _c("p", { staticClass: "normal content_nr" }, [
                _vm._v(
                  " CheerTech团结了一批优秀的IT销售、研发、运营人才， 公司核心团队曾在华为、思科、阿里、新浪、网易、陆金所、携程、饿了么、方正集团 等大型ICT、互联网、金融头部企业拥有多年的销售、研发、运营经验，在金融、政府、能源、电力、运营商、互联网等多个行业 拥有大型数据中心、云计算建设经验，总部设立在上海张江高科技园区，并设立了北京办事处、广州办事处、苏州办事处、南京办事处。 "
                ),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "title title_level1" }, [_vm._v("发展历程")]),
    _c(
      "div",
      { staticClass: "contents" },
      [
        _c(
          "a-timeline",
          { attrs: { mode: "alternate" } },
          [
            _c("a-timeline-item", [
              _c("h1", { staticClass: "title_level2" }),
              _c("div", { staticClass: "content_nr" }, [
                _c("p", [_vm._v("累计销售额突破5000万")]),
                _c("p", [_vm._v("软件产品证书:")]),
                _c("p", [_vm._v("智迩Nginx白屏化敏捷发布管理软件")]),
                _c("p", [_vm._v("智迩负载均衡集中信息化管理平台")]),
                _c("p", [_vm._v("厂商资质:")]),
                _c("p", [_vm._v("华为金牌")]),
                _c("p", [_vm._v("F5合作伙伴")]),
              ]),
            ]),
            _c("a-timeline-item", [
              _c("h1", { staticClass: "title_level2" }),
              _c("div", { staticClass: "content_nr" }, [
                _c("p", [_vm._v("管理体系证书")]),
                _c("p", [_vm._v("IS09001质量管理体系认证证书")]),
                _c("p", [_vm._v("IS020000信息技术服务体系证书")]),
                _c("p", [_vm._v("IS027001信息安全管理体系证书")]),
              ]),
            ]),
            _c("a-timeline-item", [
              _c("h1", { staticClass: "title_level2" }),
              _c("div", { staticClass: "content_nr" }, [
                _c("p", [_vm._v("软件著作权：")]),
                _c("p", [_vm._v("智迩云金融安全监管一体化平台")]),
                _c("p", [_vm._v("智迩Nginx白屏化敏捷发布管理软件")]),
                _c("p", [_vm._v("智迩负载均衡集中信息化管理平台")]),
              ]),
            ]),
            _c("a-timeline-item", [
              _c("h1", { staticClass: "title_level2" }),
              _c("div", { staticClass: "content_nr" }, [
                _c("p", [_vm._v("公司成立于2019年6月27日，注册资金5000万")]),
                _c("p", [_vm._v("总部位于上海高新创业园区")]),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }